@import url('https://fonts.googleapis.com/css?family=Nunito:400,700,800');

* {
  box-sizing: border-box;
}

body {
  /* max-width: 1860px; */
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.active{
  text-decoration: underline
}

.App{
  text-align: "center",
  
}

.ExampleStuckContent {
  position: relative;
  /* padding: 10px; */
}

/* .ExampleStuckContent__label {
  margin-top: 20px;
  position: absolute !important;
  border-radius: 2px;
  bottom: 5px;
  right: 5px;
  color: white;
  padding: 5px;
} */

.ExampleStuckContent--stuck-top {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  z-index: 1000;
  padding: 10px;
  background-color: white;
  padding-top: 45px;
}

@media only screen and (max-width: 768px) {
  .ExampleStuckContent--stuck-top {
    padding-top: 35px;
  }
}
/* .ExampleStuckContent--stuck-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  
  z-index: 1;
} */
.ExampleStuckContent--stuck-left {
  outline: 5px solid cyan;
}
.ExampleStuckContent--stuck-right {
  outline: 5px solid salmon;
}

ul li::marker {
  justify-content: center;
}
